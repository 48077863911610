import React, { useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'

function InputFile (props) {
  const { label, name, fileURL, ...rest } = props
  const [file, setFile] = useState(undefined);
  const handleChange = (name, e, setFieldValue) => {
      // console.log(e.target.files[0])
    // const value = e.target.value;
    const value = e.target.files[0];
    // console.log(props.callback);
    setFieldValue(name, value)
    if(e.target.files.length !== 0){
      setFile(URL.createObjectURL(e.target.files[0]));
    }
    if(props.callback){
      // console.log(`value ${value}`)
      props.callback(value);

    }
  }
  return (
    <div className='form-control'>
      <label htmlFor={name}>{label}</label>
      {/* <Field id={name} name={name} {...rest} /> */}
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      { fileURL ? <img src={file ? file : fileURL} width={150} height={115} /> : file && <img src={file} width={150} height={115} />}
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form
          const { value } = field
          return (
            <input type="file" id={name} name={name} {...rest} onChange={e => handleChange(name, e, setFieldValue)} style={{marginLeft: 10}} />
            
          )
        }}
      </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default InputFile